import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ResponseZCore } from "../models/response-zcore";
import { OrmService } from "./orm.service";
import { lastValueFrom } from "rxjs";
import { StorageService } from "./storage.service";

@Injectable()
export class UsuarioService {
  private userCurrent: any = null;

  constructor(
    private http: HttpClient,
    private ormService: OrmService,
    private storageService: StorageService
  ) {}

  login(usuario: any, senha: any): Observable<ResponseZCore> {
    return this.http.post<ResponseZCore>(environment.BASE_URL + "/sso/auth", {
      username: usuario,
      password: senha,
    });
  }

  async currentUser(): Promise<any> {
    return await lastValueFrom(
      this.http.post<ResponseZCore>(
        environment.BASE_URL + "/sso/current-user",
        {}
      )
    );
  }

  async listUserCompany(limit = 10, skip = 0): Promise<any[]> {
    const currentUser = await this.currentUser();
    let companyList: any[] = [];
    const result = await lastValueFrom(
      this.http.post<ResponseZCore>(
        environment.BASE_URL + "/administrative/users/list-user-company",
        {
          schema: "empresaUsuario",
          filter: {
            usuario: currentUser._id,
            createdAt: -1,
            empresa: 1,
          },
          sort: { name: 1 },
          select: {},
          limit: 1000,
          skip: 0,
          populate: {},
          secondsCache: 1,
        }
      )
    );
    if (result && result.success) {
      companyList = result.data.map((item) => item.empresa);
    }
    return companyList;
  }
}
