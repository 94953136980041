import { NavigationItem } from "src/@vex/interfaces/navigation-item.interface";

export const menuMaster: NavigationItem[] = [
  {
    type: "subheading",
    label: "Dashboards",
    children: [
      {
        type: "link",
        label: "Analítico",
        route: "/analitico",
        icon: "mat:insights",
        routerLinkActiveOptions: { exact: true },
      },
    ],
  },
  {
    type: "subheading",
    label: "Agência",
    children: [
      {
        type: "link",
        label: "Correntistas",
        route: "/correntistas",
        icon: "mat:account_balance_wallet",
      },
      {
        type: "dropdown",
        label: "Contas",
        icon: "mat:account_balance",
        children: [
          {
            type: "link",
            label: "Aguardando Documentos",
            route: "/contas/aguardando-documentos",
          },
          {
            type: "link",
            label: "Criadas",
            route: "/contas/criadas",
          },
          {
            type: "link",
            label: "Em Analise",
            route: "/contas/em-analise",
          },
          {
            type: "link",
            label: "Pré Aprovadas",
            route: "/contas/pre-aprovadas",
          },
          // {
          //   label: "Aguard. Atualização",
          //   route: "/contas/aguardando-atualizacao",
          // },
          {
            type: "link",
            label: "Não Aprovadas",
            route: "/contas/nao-aprovadas",
          },
          // {
          //   type: "link",
          //   label: "Aprovadas",
          //   route: "/contas/aprovadas",
          // },
        ],
      },
    ],
  },
  {
    type: "subheading",
    label: "Investimentos",
    children: [
      {
        type: "link",
        label: "Investidores",
        route: "/investidores",
        icon: "mat:dashboard",
      },
    ],
  },
  {
    type: "subheading",
    label: "Emprestimos",
    children: [
      {
        type: "dropdown",
        label: "Empréstimo",
        icon: "mat:assessment",
        children: [
          {
            type: "link",
            label: "Solicitação",
            route: "/emprestimos/solicitacoes",
          },
          {
            type: "link",
            label: "Aguardando Cliente",
            route: "/emprestimos/documentacao-cliente",
          },
          {
            type: "link",
            label: "Documentação Enviada",
            route: "/emprestimos/documentacao-enviada",
          },
          {
            type: "link",
            label: "Parecer Jurídico",
            icon: "mat:account_balance",
            route: "/emprestimos/juridico",
          },
          {
            type: "link",
            label: "Aprovados",
            route: "/emprestimos/aprovados",
          },
          {
            type: "link",
            label: "Não Aprovados",
            route: "/emprestimos/reprovados",
          },
        ],
      },
    ],
  },
  {
    type: "subheading",
    label: "Administrativo",
    children: [
      {
        type: "link",
        label: "Empresas",
        route: "/empresas",
        icon: "mat:business",
      },
      {
        type: "link",
        label: "Licenciados",
        route: "/licenciados",
        icon: "mat:verified_user",
      },
      {
        type: "link",
        label: "Usuários",
        route: "/usuarios",
        icon: "mat:supervisor_account",
      },
      {
        type: "dropdown",
        label: "Produtos Emprestimo",
        icon: "mat:markunread_mailbox",
        children: [
          {
            type: "link",
            label: "Categorias",
            route: "/produtos-emprestimos/categorias",
          },
          {
            type: "link",
            label: "Produtos",
            route: "/produtos-emprestimos/produtos",
          },
        ],
      },
    ],
  },
];

export const menuMasterState: NavigationItem[] = [
  {
    type: "subheading",
    label: "Dashboards",
    children: [
      {
        type: "link",
        label: "Analítico",
        route: "/analitico",
        icon: "mat:insights",
        routerLinkActiveOptions: { exact: true },
      },
    ],
  },
  {
    type: "subheading",
    label: "Agência",
    children: [
      {
        type: "link",
        label: "Correntistas",
        route: "/correntistas",
        icon: "mat:account_balance_wallet",
      },
      {
        type: "dropdown",
        label: "Contas",
        icon: "mat:account_balance",
        children: [
          {
            type: "link",
            label: "Criadas",
            route: "/contas/criadas",
          },
          {
            type: "link",
            label: "Em Analise",
            route: "/contas/em-analise",
          },
          {
            type: "link",
            label: "Não Aprovadas",
            route: "/contas/nao-aprovadas",
          },
          // {
          //   type: "link",
          //   label: "Aprovadas",
          //   route: "/contas/aprovadas",
          // },
        ],
      },
    ],
  },
  {
    type: "subheading",
    label: "Investimentos",
    children: [
      {
        type: "link",
        label: "Investidores",
        route: "/investidores",
        icon: "mat:dashboard",
      },
    ],
  },
  {
    type: "subheading",
    label: "Emprestimos",
    children: [
      {
        type: "dropdown",
        label: "Empréstimo",
        icon: "mat:assessment",
        children: [
          {
            type: "link",
            label: "Solicitação",
            route: "/emprestimos/solicitacoes",
          },
          {
            type: "link",
            label: "Aguardando Cliente",
            route: "/emprestimos/documentacao-cliente",
          },
          {
            type: "link",
            label: "Parecer Jurídico",
            icon: "mat:account_balance",
            route: "/emprestimos/juridico",
          },
          {
            type: "link",
            label: "Aprovados",
            route: "/emprestimos/aprovados",
          },
          {
            type: "link",
            label: "Não Aprovados",
            route: "/emprestimos/reprovados",
          },
        ],
      },
    ],
  },
  {
    type: "subheading",
    label: "Administrativo",
    children: [
      {
        type: "link",
        label: "Usuários",
        route: "/usuarios",
        icon: "mat:supervisor_account",
      },
    ],
  },
];
export const menuManager: NavigationItem[] = [];
export const menuAgent: NavigationItem[] = [
  {
    type: "subheading",
    label: "Dashboards",
    children: [
      {
        type: "link",
        label: "Analítico",
        route: "/analitico",
        icon: "mat:insights",
        routerLinkActiveOptions: { exact: true },
      },
    ],
  },
  {
    type: "subheading",
    label: "Agência",
    children: [
      {
        type: "link",
        label: "Correntistas",
        route: "/correntistas",
        icon: "mat:account_balance_wallet",
      },
      {
        type: "dropdown",
        label: "Contas",
        icon: "mat:account_balance",
        children: [
          {
            type: "link",
            label: "Criadas",
            route: "/apps/help-center/getting-started",
          },
          {
            type: "link",
            label: "Em Analise",
            route: "/apps/help-center/getting-started",
          },
          {
            type: "link",
            label: "Não Aprovadas",
            route: "/apps/help-center/getting-started",
          },
          {
            type: "link",
            label: "Aprovadas",
            route: "/apps/help-center/getting-started",
          },
        ],
      },
    ],
  },
  {
    type: "subheading",
    label: "Investimentos",
    children: [
      {
        type: "link",
        label: "Investidores",
        route: "/investidores",
        icon: "mat:dashboard",
      },
    ],
  },
  {
    type: "subheading",
    label: "Emprestimos",
    children: [
      {
        type: "link",
        label: "Clientes",
        route: "/apps/scrumboard",
        icon: "mat:assessment",
      },
    ],
  },
];
