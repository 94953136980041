export const environment = {
  production: false,
  CLIENT_ID:
    "035bbcd3c17cfdace3edab0de3d70168zcore761e0678993eb405acb905ad5d77cd07",
  ENV: "dsv",
  BASE_URL_CORE4YOU: "https://core4u-apim.tech4u.services/v1",
  // BASE_URL_CORE4YOU: "http://127.0.0.1:3000/v1",
  BASE_URL: "https://api.theiki.com.br/api",
  // BASE_URL: "http://192.168.0.225:3000/api",
  BASE_URL_CEP: "https://viacep.com.br/ws",

  //suporte@sorrisobank.com.br
};
