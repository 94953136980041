import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Company } from "../interfaces/company.interface";
import { OrmService } from "./orm.service";
import { lastValueFrom } from "rxjs";
import { environment } from "src/environments/environment";
import { Graduate } from "../interfaces/graduate.interface";
import { BaseService } from "./base.service";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class GraduateService extends BaseService {
  constructor(
    protected http: HttpClient,
    protected storageService: StorageService
  ) {
    super(http, storageService);
  }

  body = {
    schema: "graduates",
    filter: {},
    select: {},
    sort: {
      name: 1,
    },
    limit: 1000,
    skip: 0,
    populate: {},
    secondsCache: 1,
  };

  async create(gradute: Graduate): Promise<any> {
    const transaction = this.http.post(
      `${environment.BASE_URL}/administrative/graduates/register-graduate`,
      gradute
    );
    const result: any = await lastValueFrom(transaction);
    return result?.data as Graduate;
  }

  async update(gradute: Graduate): Promise<any> {
    const transaction = this.http.post(
      `${environment.BASE_URL}/administrative/graduates/update-graduate`,
      gradute
    );
    const result: any = await lastValueFrom(transaction);
    return result?.data as Graduate;
  }

  async countGraduates(body: any) {

    const transaction = this.http.post(
      `${environment.BASE_URL}/administrative/graduates/count-graduates`,
      body
    );
    const result: any = await lastValueFrom(transaction);
    return result?.data;
  }

  async getGraduates(body: any, ignoreGraduate: boolean = false) {
    return await this.postFind(
      "/administrative/graduates/list-graduates",
      body,
      ignoreGraduate
    );
  }

  async getGraduatesPaginator(body:any) {
    const transaction = this.http.post(
      `${environment.BASE_URL}/administrative/graduates/list-graduates`,
      body
    );
    const result: any = await lastValueFrom(transaction);
    return result?.data as Graduate[];
  }
}
