import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OrmService } from "./orm.service";
import { lastValueFrom } from "rxjs";
import { environment } from "src/environments/environment";
import { User } from "../interfaces/user.interface";
import { UserDetail } from "../interfaces/userDetail.interface";
import { StorageService } from "./storage.service";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class FileSystemService extends BaseService {
  constructor(
    protected http: HttpClient,
    protected storageService: StorageService
  ) {
    super(http, storageService);
  }

  async getFileUrlTemp(body): Promise<any> {
    return this.postFind("/public/get-file-url-temp", body, true);
  }
}
