import { ErrorUnauthorizedComponent } from "./shared/components/error-unauthorized/error-unauthorized.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CustomLayoutComponent } from "./custom-layout/custom-layout.component";
import { QuicklinkModule, QuicklinkStrategy } from "ngx-quicklink";
import { AuthGuardService } from "./shared/services/auth-guard.service";
import { ProfileEnum } from "./shared/constantes/profile.enum";

const routes: Routes = [
  {
    path: "login",
    loadChildren: () =>
      import("./core/auth/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "",
    redirectTo: "analitico",
    pathMatch: "full",
  },
  {
    path: "",
    component: CustomLayoutComponent,
    children: [
      {
        path: "analitico",
        loadChildren: () =>
          import(
            "./modules/dashboard-analytics/dashboard-analytics.module"
          ).then((m) => m.DashboardAnalyticsModule),
        canActivate: [AuthGuardService],
        data: { expectedRole: [ProfileEnum.MASTER, ProfileEnum.MASTER_STATE, ProfileEnum.AGENT] },
      },
      {
        path: "usuarios",
        loadChildren: () =>
          import("./modules/users/users.module").then((m) => m.UsersModule),
        canActivate: [AuthGuardService],
        data: { expectedRole: [ProfileEnum.MASTER, ProfileEnum.MASTER_STATE] },
      },
      {
        path: "empresas",
        loadChildren: () =>
          import("./modules/companies/companies.module").then(
            (m) => m.CompaniesModule
          ),
        canActivate: [AuthGuardService],
        data: { expectedRole: [ProfileEnum.MASTER, ProfileEnum.MASTER_STATE] },
      },
      {
        path: "licenciados",
        loadChildren: () =>
          import("./modules/graduates/graduates.module").then(
            (m) => m.GraduatesModule
          ),
        canActivate: [AuthGuardService],
        data: { expectedRole: [ProfileEnum.MASTER, ProfileEnum.MASTER_STATE] },
      },
      {
        path: "correntistas",
        loadChildren: () =>
          import("./modules/customers/customers.module").then(
            (m) => m.CustomersModule
          ),
        canActivate: [AuthGuardService],
        data: { expectedRole: [ProfileEnum.MASTER, ProfileEnum.MASTER_STATE, ProfileEnum.AGENT] },
      },
      {
        path: "investidores",
        loadChildren: () =>
          import("./modules/investors/investors.module").then(
            (m) => m.InvestorsModule
          ),
        canActivate: [AuthGuardService],
        data: { expectedRole: [ProfileEnum.MASTER, ProfileEnum.MASTER_STATE, ProfileEnum.AGENT] },
      },
      {
        path: "contas/aguardando-documentos",
        loadChildren: () =>
          import(
            "./modules/accounts/accounts-waiting-document/accounts-waiting-document.module"
          ).then((m) => m.AccountsWaitingDocumentModule),
        canActivate: [AuthGuardService],
        data: {
          expectedRole: [
            ProfileEnum.MASTER,
            ProfileEnum.MASTER_STATE,
            ProfileEnum.AGENT,
          ],
        },
      },
      {
        path: "contas/criadas",
        loadChildren: () =>
          import(
            "./modules/accounts/accounts-created/accounts-created.module"
          ).then((m) => m.AccountsCreatedModule),
        canActivate: [AuthGuardService],
        data: { expectedRole: [ProfileEnum.MASTER, ProfileEnum.MASTER_STATE, ProfileEnum.AGENT] },
      },
      {
        path: "contas/pre-aprovadas",
        loadChildren: () =>
          import(
            "./modules/accounts/accounts-pre-approved/accounts-pre-approved.module"
          ).then((m) => m.AccountsApprovedModule),
        canActivate: [AuthGuardService],
        data: { expectedRole: ["Master", "Agente"] },
      },
      {
        path: "contas/em-analise",
        loadChildren: () =>
          import(
            "./modules/accounts/accounts-under-analysis/accounts-under-analysis.module"
          ).then((m) => m.AccountsUnderAnalysisModule),
        canActivate: [AuthGuardService],
        data: { expectedRole: [ProfileEnum.MASTER, ProfileEnum.MASTER_STATE, ProfileEnum.AGENT] },
      },
      {
        path: "contas/nao-aprovadas",
        loadChildren: () =>
          import(
            "./modules/accounts/accounts-unapproved/accounts-unapproved.module"
          ).then((m) => m.AccountsUnapprovedModule),
        canActivate: [AuthGuardService],
        data: { expectedRole: [ProfileEnum.MASTER, ProfileEnum.MASTER_STATE, ProfileEnum.AGENT] },
      },
      {
        path: "contas/aguardando-atualizacao",
        loadChildren: () =>
          import(
            "./modules/accounts/accounts-waiting-update/accounts-waiting-update.module"
          ).then((m) => m.AccountsWaitingUpdateModule),
        canActivate: [AuthGuardService],
        data: { expectedRole: [ProfileEnum.MASTER, ProfileEnum.MASTER_STATE, ProfileEnum.AGENT] },
      },
      {
        path: "emprestimos",
        loadChildren: () =>
          import("./modules/loan/loans.module").then((m) => m.LoansModule),
        canActivate: [AuthGuardService],
        data: { expectedRole: [ProfileEnum.MASTER, ProfileEnum.MASTER_STATE, ProfileEnum.AGENT] },
      },
      {
        path: "produtos-emprestimos",
        loadChildren: () =>
          import("./modules/loan/loans.module").then((m) => m.LoansModule),
        canActivate: [AuthGuardService],
        data: { expectedRole: ["Master"] },
      },
      {
        path: "configuracoes-conta",
        loadChildren: () =>
          import("./modules/account-settings/account-settings.module").then(
            (m) => m.AccountSettingsModule
          ),
        canActivate: [AuthGuardService],
        data: { expectedRole: [ProfileEnum.MASTER, ProfileEnum.MASTER_STATE, ProfileEnum.AGENT] },
      },
      {
        path: "error-unauthorized",
        loadChildren: () =>
          import(
            "./shared/components/error-unauthorized/error-unauthorized.module"
          ).then((m) => m.errorUnauthorizedModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: QuicklinkStrategy,
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "corrected",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule, QuicklinkModule],
})
export class AppRoutingModule {}
